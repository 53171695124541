@tailwind base;
@tailwind components;
* {
  box-sizing: border-box;
}
.tooltip {
  @apply hidden;
}

.has-tooltip:hover .tooltip {
  @apply block;
}

.parent-scroll {
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
}
html {
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
}
.child-scroll {
  scroll-snap-align: start;
}
.footer-scroll {
  scroll-snap-align: end;
}

@tailwind utilities;

:root {
  --toastify-toast-width: 420px !important;
}

/* 
For debugging purposes
* {
    background: #000 !important;
    color: #0f0 !important;
    outline: solid #f00 1px !important;
  } 
*/
